/* Manifest List, Vue Component */
<template>
  <v-card id="manifest-list">
    <v-card-title>Manifests</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'manifest-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Manifest</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <CountrySelect
          class="mr-4"
          v-model="filter.CountryId"
          :outlined="true"
          :inverted="false"
          :hide-details="true"
          :dense="true"
          :filter="{filter:{CanManifest: true}}"
        />

        <v-select
          v-model="filter.Closed"
          :items="filterItems.Closed"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Status"
          class="mr-4"
        ></v-select>

      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

      <template #[`item.CountryId_Name`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'manifest-view', params: { id: item.Id } }"
          v-if="item.Closed"
        >
          {{ item.CountryId_Name }}
        </router-link>
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'manifest-edit', params: { id: item.Id } }"
          v-if="!item.Closed"
        >
          {{ item.CountryId_Name }}
        </router-link>
      </template>

      <template #[`item.ManifestDate`]="{item}">
        {{ Display.DisplayDate(item.ManifestDate) }}
      </template>

      <template #[`item.Closed`]="{item}">
        <v-chip
          color="error"
          text-color="white"
          small
          v-if="item.Closed"
        >Closed</v-chip>
        <v-chip
          color="primary"
          text-color="white"
          small
          v-if="!item.Closed"
        >Open</v-chip>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown
            :item="item"
            :actionOptions="actionOptions"
          />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import store from '@/store'

import { mdiPlus } from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import CountrySelect from '@/views/country/CountrySelect'

export default {
  components: {
    CountrySelect,
  },
  setup() {
    // define cols
    const tableColumns = [
      { text: 'Country', value: 'CountryId_Name' },
      { text: 'Date', value: 'ManifestDate' },
      { text: 'Index', value: 'ManifestIndex' },
      { text: 'Status', value: 'Closed' },
    ]

    // threee params for fetching
    const search = ref({})
    const filter = ref({})
    const options = ref({
      sortBy: ['ManifestDate'],
      sortDesc: [true],
      itemsPerPage: 20,
    })
    const footerProps = {
      'items-per-page-options': [10, 20, 50, 100, -1],
    }

    // items
    const items = computed(() => store.state.app.Manifests || [])
    const totalItems = computed(() => store.state.app.ManifestsTotal || 0)
    const selectedItems = ref([])
    const loading = ref(false)

    // data for filter fields
    const filterItems = ref({
      Closed: [
        { text: 'Open', value: false },
        { text: 'Closed', value: true },
      ],
    })

    // method for data loading via vuex
    const fetchData = () => {
      store
        .dispatch('app/fetchManifests', {
          search: search.value,
          options: options.value,
          filter: filter.value,
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    watch(
      [search, filter, options],
      () => {
        loading.value = true
        fetchData()
      },
      { deep: true },
    )

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      footerProps,
      Validators,
      Display,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
